@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_panel.pcss';

.lia-panel-list {
  &-title {
    margin: 0;
  }

  &-item {
    background-color: var(--lia-panel-bg-color);
    border-color: var(--lia-panel-border-color);
  }

  &-standard,
  &-divider {
    @include panel-defaults();

    .lia-panel-list {
      &-header,
      &-footer {
        padding: $lia-panel-spacing-md $lia-panel-spacing-lg;

        @media (--lia-breakpoint-down-sm) {
          padding-left: $lia-panel-spacing-sm;
          padding-right: $lia-panel-spacing-sm;
        }
      }
      &-body {
        overflow: hidden;

        &:not(.lia-has-footer) {
          border-bottom-left-radius: var(--lia-panel-border-radius);
          border-bottom-right-radius: var(--lia-panel-border-radius);
        }
      }
    }
  }

  &-bubble,
  &-spaced {
    .lia-panel-list {
      &-header {
        padding: 0 0 $lia-panel-spacing-md;

        &.lia-has-sub-header {
          padding-bottom: $lia-panel-spacing-xs;
        }
      }
      &-footer {
        @include panel-defaults();
        padding: $lia-panel-spacing-md $lia-panel-spacing-sm;
        margin-top: var(--lia-list-li-mb-md);
      }
    }
  }

  &-none,
  &-spaced {
    .lia-panel-list-item {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &-spaced {
    .lia-panel-list-header + .lia-panel-list-body-empty {
      margin-top: calc(-1 * #{$lia-panel-spacing-md});
    }
  }

  &-standard {
    .lia-panel-list-body {
      &:not(.lia-panel-list-body-empty) {
        padding: $lia-panel-spacing-md $lia-panel-spacing-lg;

        &:not(:last-child) {
          padding-bottom: 0;
        }
        @media (--lia-breakpoint-down-sm) {
          padding-left: $lia-panel-spacing-sm;
          padding-right: $lia-panel-spacing-sm;
        }
      }
    }
    .lia-panel-list-header {
      &.lia-has-sub-header {
        padding-bottom: $lia-panel-spacing-xs;
      }
      + .lia-panel-list-body-empty {
        margin-top: calc(-1 * #{$lia-panel-spacing-md});
      }
      + .lia-panel-list-body {
        padding-top: 0;
      }
    }
  }

  &-divider {
    .lia-panel-list {
      &-header {
        border-bottom: 1px solid var(--lia-panel-border-color);
      }
      &-footer {
        border-top: 1px solid var(--lia-panel-border-color);
      }
    }
  }

  &-bubble {
    .lia-panel-list-item {
      @include panel-defaults();
      padding: $lia-panel-spacing-md $lia-panel-spacing-lg;
    }
    .lia-panel-list-body-empty {
      background-color: var(--lia-panel-bg-color);
    }
  }
}
